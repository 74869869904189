import React, { useState, useContext, useEffect } from "react";
// import { Button } from "react-bootstrap";
import axios from 'axios';
import "antd/dist/antd.css";
import { Tabs, Row, Col, Select, notification, Button, Switch, Checkbox } from "antd";
import { DatePicker, Space, Spin, Alert } from "antd";
import { message } from "antd";
import "bootstrap/dist/css/bootstrap.min.css";
import * as FileSaver from "file-saver";

import * as XLSX from "xlsx";
import { DownloadOutlined, CloseOutlined, CheckOutlined, SearchOutlined  } from '@ant-design/icons';

import { } from "react-bootstrap";
import { FilterContext, } from "../../context/FilterContext"
import { FxEuroTable } from "../../components/Layout/Pages/DataTable"
import dal from '../../dal/api-call';
import "./table.css";
import { ExportExcel } from "./ExportExcel";

const { Option } = Select;


// const EXCEL_DATA_LOAD = "https://api-finance-ui-dev.middleware-dev.tvx-test.cloud/ui/rpa/auto"
// const EXCEL_DATA_ENDPOINT = "https://api-finance-ui-dev.middleware-dev.tvx-test.cloud/ui/report/cms"

// const openNotification = (type, message) => {
//     notification[type]({
//         message: 'Message',
//         description: message,
//         onClick: () => {
//             console.log('Notification Clicked!');
//         },
//     });
// };
let limit = 500;
let offset = 0;

export const FilterComponent = () => {
    

    const { filterState, filterDispatch } = useContext(FilterContext);
    const exportButton = <Button type="primary" variant="primary" size="sm" style={{marginBottom: "4px"}} onClick={(e) => exportToCSV()}>Export</Button>;

    // console.log(filterState);
    const addTextFormat = (ws, columnList, numberFormat) => {
        let range = XLSX.utils.decode_range(ws["!ref"]);
        for(let R = range.s.r + 1; R <= range.e.r; R++) {
            for (var c = 0 ; c <= columnList.length; c++){
                let cell = ws[XLSX.utils.encode_cell({r:R,c:filterState.columns.indexOf(columnList[c])})];
                if(!cell) continue;
                if(isNaN(cell.v)){
                    cell.t = 's';
                    cell.v = "-";
                }
                else{
                    cell.t = 'n';
                    cell.z = numberFormat;
                }
            }
        }
    }
    
    const exportToCSV = () => {

        try{
            // console.log("test1")
            if (filterState.isApiDataFetched == true){
                // console.log("export")
                const fileType =
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

                const fileExtension = ".xlsx";
                const fileName = filterState.country + "_"+ filterState.toDate;
                // console.log("Export Excel :::"+filterState.finalData);
                // console.log("Type of finalData :::", typeof(filterState.finalData))
                let transData = JSON.parse(JSON.stringify(filterState.finalData));
                // console.log("Export Excel :::", transData);
                let filterColumn = filterState.columns.filter((column)=>!["Branch","CCY","C/R"].includes(column))
                transData.map( el=>{
                    delete el["index"];
                    delete el["key"];
                    delete el["Unnamed: 0"];
                    delete el["Order Ref"];
                    delete el["Ordered"];
                    for(let count=0; count<filterColumn.length;count++){
                        el[filterColumn[count]] = parseFloat(el[filterColumn[count]].replace(/,/g,""))
                    }
                });

                const ws = XLSX.utils.json_to_sheet(transData, { header: filterState.columns} );
                addTextFormat(ws,filterColumn,"#,##0")
                const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

                const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

                const fileData = new Blob([excelBuffer], { type: fileType });

                FileSaver.saveAs(fileData, fileName + fileExtension);
            }
            else{
                message.info("Data is not available. Please try again.");
            }
            
        }catch(error){
            message.info(error.message);
        }
        

    };

    // const openNotification = (type, message) => {
    //     notification[type]({
    //         message: 'Message',
    //         description: message,
    //         onClick: () => {
    //             // console.log('Notification Clicked!');
    //         },
    //     });
    // };

    
    function handleSubmit(e, filterDispatch) {
        e.preventDefault();
        try{
            offset = 0;

            if (filterState.toDate == "" || filterState.country == ""){
                throw new Error("Please Provide Mandatory Fields !!!")
            }else{
                
                // const jsonData= require('../../resource/Netherlands_11_05_2022.json'); 
                // console.log("==========================================")
                // console.log(jsonData);
                // try {
                //     const response = await dal.request(`${process.env.REACT_APP_API_BASE_PATH}stockupdate-get?fromDate=${filterState.fromDate}&toDate=${filterState.toDate}&country=${filterState.country}&reportType=${filterState.reportType}&limit=${limit}&offset=${offset}`)
                //     let result = response.data;
                //     filterDispatch({type: "RESET_API_DATA"})
                //     filterDispatch({type: "FETCH_API_DATA", payload: result});

                //     let offsetCounter = 0;
                //     if (offset <= result.totalRecords){

                //         while (offsetCounter <= result.batchSize) {
                //             offsetCounter = offsetCounter === 0 ? offsetCounter + 1 : offsetCounter;
                //             offset = offsetCounter * limit;

                //             const response = await dal.request(`${process.env.REACT_APP_API_BASE_PATH}stockupdate-get?fromDate=${filterState.fromDate}&toDate=${filterState.toDate}&country=${filterState.country}&reportType=${filterState.reportType}&limit=${limit}&offset=${offset}`)
                //             let result = response.data;
                //             filterDispatch({type: "FETCH_API_DATA", payload: result});
                //             offsetCounter = offsetCounter + 1;
                //         }
                //         if (offsetCounter > result.batchSize) {
                //             filterDispatch({type: "API_DATA_STATUS", value: true});
                //         }
                //     }else {
                //         filterDispatch({type: "API_DATA_STATUS", value: true}); 
                //     }
                    
                //     // return result;

                // }catch(error){
                //     console.log(error)
                // }
                filterDispatch({type: "HANDLE_SUBMIT", filterState: filterState, dispatch: filterDispatch})
                // filterDispatch({type: "FETCH_API_DATA", payload: jsonData});
                // filterDispatch({type: "API_DATA_STATUS", value: true});
            }
        }catch(error){
            message.info(error.message);
            
        }
        
        

    }
    // function runBot() {
    //     // setIsDataLoading(true)
    //     filterDispatch({type: "SET_DATA_LOADING_FLAG", value: true})
    //     axios.post(EXCEL_DATA_LOAD, JSON.stringify({ fromDate: filterState.fromDate, toDate: filterState.toDate, country: filterState.country, reportType: filterState.reportType }))
    //         .then(response => {
    //             openNotification('success', 'File has been downloaded successfully')
    //             // setIsDataLoading(false)
    //             filterDispatch({type: "SET_DATA_LOADING_FLAG", value: false})
    //             loadData();
    //         })
    //         .catch(err => {
    //             // setIsDataLoading(false)
    //             filterDispatch({type: "SET_DATA_LOADING_FLAG", value: false})
    //             // setIsFetching(true)
    //             filterDispatch({type: "SET_DATA_FETCH_FLAG", value: true})
    //             loadData();
    //         })
    // }

    // function loadData() {
    //     // setIsFetching(true)
    //     filterDispatch({type: "SET_DATA_FETCH_FLAG", value: true})
    //     axios.post(EXCEL_DATA_ENDPOINT, { bucketName: 'tvx-middleware-dev', fileName: 'OrderByDemonination.xlsx' })
    //         .then(response => {
    //             if (response) {
    //                 // setData(response.data.data)
    //                 if (response.data.columns) {
    //                     let _columns = response.data.columns.map((element, index) => {
    //                         return {
    //                             title: element,
    //                             dataIndex: element,
    //                             key: index,
    //                         }
    //                     })
    //                     // setColumns(_columns)
    //                     filterDispatch({type: "FETCH_DATA", data: response.data.data, columns: _columns, totalRecords: response.data.totalRecords})
    //                 }
    //             }
    //             // setIsFetching(false)
    //             filterDispatch({type: "SET_DATA_FETCH_FLAG", value: false})
    //             // setTotalCount(response.data.totalRecords)
    //         })
    // }
    // console.log("Filter component :: ", filterState);
    return (
        <div>
            {/* <div>
                <p><i>Select Date Range for CMS Report.</i></p>
                
            </div> */}
            <div
                style={{
                    background:"linear-gradient(178deg, rgb(0 11 78), transparent);",
                    backgroundColor: "#0c3560",
                    padding: "12px",
                    height: "55px",
                    width: "100%",
                }}
            >
                
                <div 
                    style={{
                        float:"left",
                        width:"10%",
                        marginLeft: "5px",
                    }}
                >
                    <DatePicker style={{width: "100%"}}onChange={(date) => filterDispatch({type: "SET_TO_DATE", value: date})} id="toDatePicker" />
                </div>
                <div 
                    style={{
                        float:"left",
                        width:"15%",
                        marginLeft: "12px",
                    }}
                >   
                    <Select
                            name="country"
                            optionFilterProp="children"
                            style={{ width: "100%",}}
                            value={filterState.country}
                            onChange={(value) => filterDispatch({type: "SET_COUNTRY", value: value})}
                        >
                            
                            <option value="" disabled>Select Country </option>
                            <Option value="Netherlands">Netherlands</Option>
                            
                    </Select>
                </div>
                
                <div 
                    style={{
                        float: "left",
                        width: "12%",
                        marginLeft: "12px",
                        marginTop: "3px"
                    }}
                >
                    <Checkbox defaultChecked = {false} checked={filterState.stocksFromTillsFlag} onChange = {(e) => filterDispatch({type: "SET_OSR_FLAG", value: e})}><span style={{color: "white", fontFamily: "Lato Light", fontStyle: "normal", fontWeight: "bold"}}>Show stocks from till</span></Checkbox>
                    
                </div>
                <div 
                    style={{
                        float:"left",
                        width:"10%",
                        // marginLeft: "5px",
                    }}
                >
                    <Button size="sm" icon= {<SearchOutlined />} loading={filterState.isDataLoading} style={{ background: "repeat-y scroll 84% 0 #ed1c2e", backgroundColor: "#ed1c2e", borderColor: "#ed1c2e", fontFamily: "Lato Light", fontStyle: "normal", fontWeight: "bold", letterSpacing: "1px"}} type="primary" onClick={(e) => handleSubmit(e, filterDispatch)}>Search</Button>
                </div>
                <div 
                    style={{
                        float:"right",
                        width:"10%",
                    }}
                >
                    <Button type="primary" icon={<DownloadOutlined />} style={{float: "right", background: "repeat-y scroll 84% 0 #ed1c2e", backgroundColor: "#ed1c2e", borderColor: "#ed1c2e", fontFamily: "Lato Light", fontStyle: "normal", fontWeight: "bold", letterSpacing: "1px"}} variant="primary" size="sm"  onClick={(e) => exportToCSV()} >Export</Button>
                </div>
            </div>

            {filterState.isError === true ? 
                (
                    <>
                        <div
                            style={{
                                paddingTop: "15px",
                            }}
                        >
                            <Alert
                                style={{fontFamily: "Lato Light", fontStyle: "normal", fontWeight: "bold", letterSpacing: "1px", alignContent: "center"}}
                                description={filterState.errorMessage}
                                type="error"
                                closable
                            />
                        </div>
                    </>
                    
                ):(
                <>
                    {filterState.isFetching ? (
                        <Spin style={{paddingLeft: "15px", paddingTop: "20px"}}/>
                    
                    ): (
                        <>
                            { filterState && filterState.isApiDataFetched && filterState.isError === false? (<>
                                {filterState && filterState.displayMessage ? (
                                    <>
                                        <div
                                            style={{
                                                paddingTop: "15px",
                                            }}
                                        >
                                            <Alert
                                                style={{fontFamily: "Lato Light", fontStyle: "normal", fontWeight: "bold", letterSpacing: "1px", alignContent: "center"}}
                                                description={filterState.displayMessage}
                                                type="error"
                                                closable
                                            />
                                        </div>
                                        <div
                                            style={{margin: "2px 2px 2px 2px"}}
                                        >

                                        </div>
                                        <div 
                                            style={{
                                                paddingBottom: "25px",
                                                backgroundColor: "#F9FBFF",
                                                // height: "100%"
                                            }}
                                        >
                                            <FxEuroTable />
                                        </div>
                                    </>
                                    
                                ):(
                                    <div 
                                        style={{
                                            paddingBottom: "25px",
                                            backgroundColor: "#F9FBFF",
                                            // height: "100%"
                                        }}
                                    >
                                        <FxEuroTable />
                                    </div>
                                )}
                                
                            </>): (
                                ""
                            )}
                        </>
                    )}
                </>
            )}
            {/* {filterState.isFetching ? (
                <Spin style={{paddingLeft: "15px", paddingTop: "20px"}}/>
            
            ): (
                <>
                    { filterState && filterState.isApiDataFetched ? (<>
                        <div 
                            style={{
                                paddingTop: "15px",
                                backgroundColor: "#F9FBFF",
                                // height: "100%"
                            }}
                        >
                            <FxEuroTable />
                        </div>
                    </>): ("")}
                </>
            )} */}
            
        </div>
    )
    // }
}

